<template>
  <div
    class="cascader clearfix f_left"
    :style='{width:width.box}'
    :class="{z_index: isShow.sec}"
    @mouseleave="hide">
    <!-- <div class="cascader clearfix f_left"> -->
    <ul
      class='first block f_left'
      style='z-index:100'
      :style='{width:width.block}'>
      <el-scrollbar style="height: 100%;">
        <li
          class='all'
          :class='{check:allCheck}'
          @click='setCheck("all", "", "all")'
          @mouseenter="hide">{{$t('lang.all')}}</li>
        <li
          v-for='(item, index) in oneArr'
          :class='{active:controller.active.one[index],check:controller.check.one[index]}'
          :key='index'
          @mouseenter="getSecond(item, index)"
          @click='setCheck(item.bookmarkName, item.id, index, "one")'>
          <span
            class='bookmark_name f_left'
            v-if='item.id == "unclassified"'>{{$t(item.bookmarkName)}}</span>
          <span
            class='bookmark_name f_left'
            v-else>{{item.bookmarkName}}</span>
          <i
            class='iconfont f_right'
            v-if='item.children.length>0'>&#xe634;</i>
        </li>
      </el-scrollbar>
    </ul>
    <ul
      class="second block f_left"
      v-if='isShow.sec'
      @mouseenter="showSec"
      style='z-index:99'
      @mouseleave="hideSec"
      :style='{width:width.block}'>
      <!-- <ul class="second block f_left" @mouseenter="showSec" style='z-index:99' @mouseleave="hideSec"> -->
      <el-scrollbar style="height: 100%;">
        <li
          v-for='(item, index) in secondArr'
          :key='index'
          :class='{active: controller.active.two[index],check:controller.check.two[index]}'
          @mouseenter="getThree(item, index)"
          @click='setCheck(item.bookmarkName, item.id, index, "two", item.parentId)'>
          <span class='bookmark_name f_left'>{{item.bookmarkName}}</span>
          <i
            class='iconfont f_right'
            v-if='item.children.length>0'>&#xe634;</i>
        </li>
      </el-scrollbar>
    </ul>
    <ul
      class="three block f_left"
      style='z-index:98'
      v-if='isShow.three'
      @mouseenter="showThree"
      @mouseleave="hideThree"
      :style='{width:width.block}'>
      <el-scrollbar style="height: 100%;">
        <li
          v-for='(item, index) in threeArr'
          :key='index'
          :class='{active: controller.active.three[index],check:controller.check.three[index]}'
          @mouseenter="getFour(item, index)"
          @click='setCheck(item.bookmarkName, item.id, index, "three", item.parentId)'>
          <span class='bookmark_name f_left'>{{item.bookmarkName}}</span>
          <i
            class='iconfont f_right'
            v-if='item.children.length>0'>&#xe634;</i>
        </li>
      </el-scrollbar>
    </ul>
    <ul
      class="four block f_left"
      style='z-index:97'
      v-if='isShow.four'
      @mouseenter="showFour"
      @mouseleave="hideFour"
      :style='{width:width.block}'>
      <el-scrollbar style="height: 100%;">
        <li
          v-for='(item, index) in fourArr'
          :key='index'
          :class='{active:controller.active.four[index],check:controller.check.four[index]}'
          @mouseenter="getActiveArr('active','four', fourArr, '', [], index)"
          @click='setCheck(item.bookmarkName, item.id, index, "four", item.parentId)'>
          <span class='bookmark_name f_left'>{{item.bookmarkName}}</span>
          <i
            class='iconfont f_right'
            v-if='item.children.length>0'>&#xe634;</i>
        </li>
      </el-scrollbar>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['bookmark'],
  data () {
    return {
      flag: true,
      oneArr: [],
      secondArr: [],
      threeArr: [],
      fourArr: [],
      isShow: {
        sec: false,
        three: false,
        four: false
      },
      width: {
        box: '100%',
        block: '100%'
      },
      controller: {
        active: {
          one: [],
          two: [],
          three: [],
          four: []
        },
        check: {
          one: [],
          two: [],
          three: [],
          four: []
        }
      },
      index: {
        one: 0,
        two: 0,
        three: 0,
        four: 0
      },
      allCheck: true
    }
  },
  created () {
    if (this.bookmark && this.bookmark.length > 0) {
      this.initBookmark()
    }
  },
  methods: {
    initBookmark () {
      // 初始化值
      this.oneArr = this.bookmark
      /*
      * 给每级添加鼠标悬浮控制
      */
      this.getActiveArr('active', '', [], 'one', this.oneArr)
      this.initArr('check', this.oneArr, 'one')
      // 给每级添加是否选中控制
      var arr = this.addControlCheck(this.oneArr)
      this.oneArr = arr
      // 获取每级是否被选中的状态
      this.getGradeCheck('', 'one', this.oneArr)
      // console.log(this.oneArr);
    },
    // 给每级添加是否选中控制
    addControlCheck (arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].check = true
        if (arr[i].children.length > 0) {
          this.addControlCheck(arr[i].children)
        }
      }
      return arr
    },
    // 给每级添加鼠标悬浮控制及此条处于悬浮状态
    getActiveArr (type, selfKey, selefArr, key, arr, index) {
      if (selfKey) {
        this.initArr(type, selefArr, selfKey)
        this.controller[type][selfKey][index] = true
      }
      if (key) { this.initArr(type, arr, key) };
    },
    // 获取每级是否被选中的状态
    getGradeCheck (index, type, existArr) {
      var arr = []
      if (index === '') {
        for (let i = 0; i < existArr.length; i++) {
          arr.push(existArr[i].check)
        }
      } else {
        for (let i = 0; i < existArr[index].children.length; i++) {
          arr.push(existArr[index].children[i].check)
        }
      }
      this.controller.check[type] = arr
    },
    // 初始化数组
    initArr (type, arr, key) {
      this.controller[type][key] = []
      for (let i = 0; i < arr.length; i++) {
        this.controller[type][key].push(false)
      }
    },
    // 获取二级目录
    getSecond (item, index) {
      this.index.one = index
      this.hideThree()
      this.hideFour()
      this.secondArr = item.children
      // 给每级添加鼠标悬浮控制
      this.getActiveArr('active', 'one', this.oneArr, 'two', this.secondArr, index)
      // 给每级添加是否选中控制
      this.getGradeCheck(index, 'two', this.oneArr)
      if (this.secondArr.length > 0) {
        this.isShow.sec = true
        this.changeWidth(true)
      } else {
        this.isShow.sec = false
        this.changeWidth(false)
      }
    },
    // 获取三级目录
    getThree (item, index) {
      this.index.two = index
      this.hideFour()
      this.threeArr = item.children
      this.getActiveArr('active', 'two', this.secondArr, 'three', this.threeArr, index)
      // 给每级添加是否选中控制
      this.getGradeCheck(index, 'three', this.oneArr[this.index.one].children)
      if (this.threeArr.length > 0) this.isShow.three = true
      else this.isShow.three = false
    },
    // 获取四级目录
    getFour (item, index) {
      this.index.three = index
      this.fourArr = item.children
      this.getActiveArr('active', 'three', this.threeArr, 'four', this.fourArr, index)
      // 给每级添加是否选中控制
      this.getGradeCheck(index, 'four', this.oneArr[this.index.one].children[this.index.two].children)
      if (this.fourArr.length > 0) this.isShow.four = true
      else this.isShow.four = false
    },
    // 更改width
    changeWidth (show) {
      this.width.box = show ? '400%' : '100%'
      this.width.block = show ? '25%' : '100%'
    },
    // 隐藏目录
    hide () {
      this.isShow.sec = false
      this.isShow.three = false
      this.isShow.four = false
      this.changeWidth(false)
    },
    // 显示二级目录
    showSec () {
      this.isShow.sec = true
      this.changeWidth(true)
    },
    // 显示三级目录
    showThree () {
      this.isShow.sec = true
      this.changeWidth(true)
      this.isShow.three = true
    },
    // 显示四级目录
    showFour () {
      this.isShow.sec = true
      this.isShow.three = true
      this.isShow.four = true
    },
    // 隐藏二级目录
    hideSec () {
      this.isShow.sec = false
      this.changeWidth(false)
    },
    // 隐藏三级目录
    hideThree () {
      this.isShow.three = false
      this.isShow.four = false
    },
    // 隐藏四级目录
    hideFour () {
      this.isShow.four = false
    },
    // 更改上下级关联值
    changeRelative (key, val, index, parentId) {
      switch (key) {
        case 'one':
          this.oneArr[index] = this.getCheckVal(this.oneArr[index], val)
          // 更改第二层
          this.controller.check.two = this.getNextCtrlCheck(this.oneArr[index].children)
          break
        case 'two':
          this.oneArr[this.index.one].children[index] = this.getCheckVal(this.oneArr[this.index.one].children[index], val)
          // 更改第一层
          this.controller.check.one = this.getPreCtrlCheck(this.controller.check.one, this.oneArr, parentId, 'one', val).ctrlArr
          // 更改第三层
          this.controller.check.three = this.getNextCtrlCheck(this.oneArr[this.index.one].children[index].children)
          break
        case 'three':
          this.oneArr[this.index.one].children[this.index.two].children[index] = this.getCheckVal(this.oneArr[this.index.one].children[this.index.two].children[index], val)
          // 更改第二层
          var obj = this.getPreCtrlCheck(this.controller.check.two, this.oneArr[this.index.one].children, parentId, 'two', val)
          this.controller.check.two = obj.ctrlArr
          // 更改第一层
          this.controller.check.one = this.getPreCtrlCheck(this.controller.check.one, this.oneArr, obj.preParentId, 'one', obj.preVal).ctrlArr
          // 更改第四层
          this.controller.check.four = this.getNextCtrlCheck(this.oneArr[this.index.one].children[this.index.two].children[index].children)
          break
        case 'four':
          this.oneArr[this.index.one].children[this.index.two].children[this.index.three].children[index] = this.getCheckVal(this.oneArr[this.index.one].children[this.index.two].children[this.index.three].children[index], val)
          // 更改第三层
          var threeObj = this.getPreCtrlCheck(this.controller.check.three, this.oneArr[this.index.one].children[this.index.two].children, parentId, 'three', val)
          this.controller.check.three = threeObj.ctrlArr
          // 更改第二层
          var twoObj = this.getPreCtrlCheck(this.controller.check.two, this.oneArr[this.index.one].children, threeObj.preParentId, 'two', threeObj.preVal)
          this.controller.check.two = twoObj.ctrlArr
          // 更改第一层
          this.controller.check.one = this.getPreCtrlCheck(this.controller.check.one, this.oneArr, twoObj.preParentId, 'one', twoObj.preVal).ctrlArr
          break
      }
    },
    // 获取上级是否被选中的值
    getPreCtrlCheck (ctrlArr, parentArr, parentId, type, val) {
      for (var i = 0; i < parentArr.length; i++) {
        if (parentArr[i].id == parentId) {
          var flag = true; var preParentId; var preVal
          if (parentArr[i].parentId != undefined) preParentId = parentArr[i].parentId
          for (var j = 0; j < parentArr[i].children.length; j++) {
            if (parentArr[i].children[j].check == false) flag = false
          }
          if ((flag && val) || !val) {
            switch (type) {
              case 'one':
                this.oneArr[i].check = val
                break
              case 'two':
                this.oneArr[this.index.one].children[i].check = val
                break
              case 'three':
                this.oneArr[this.index.one].children[this.index.two].children[i].check = val
                break
            }
            ctrlArr[i] = val
            preVal = val
          } else {
            preVal = parentArr[i].check
          }
        }
      }
      return { ctrlArr: ctrlArr, preParentId: preParentId, preVal: preVal }
    },
    // 获取下级是否选中值
    getNextCtrlCheck (arr) {
      var newArr = []
      for (var i = 0; i < arr.length; i++) {
        newArr.push(arr[i].check)
      }
      return newArr
    },
    // 循环遍历更改是否选中值
    getCheckVal (obj, val) {
      obj.check = val // 更改存储值
      if (obj.children && obj.children.length > 0) {
        for (var i = 0; i < obj.children.length; i++) {
          obj.children[i].check = val
          this.getCheckVal(obj.children[i], val)
        }
      }
      return obj
    },
    // 设置是否选中
    setCheck (itemName, id, index, key, parentId) {
      if (index == 'all') {
        if (this.allCheck) this.allCheck = false
        else this.allCheck = true
        for (var i = 0; i < this.oneArr.length; i++) {
          this.oneArr[i].check = this.allCheck
          this.controller.check.one[i] = this.allCheck
          this.changeRelative('one', this.allCheck, i)
        }
        index = 0
      } else {
        var arr = this.controller.check[key]
        if (arr[index]) {
          arr[index] = false
          this.changeRelative(key, false, index, parentId)
        } else {
          arr[index] = true
          this.changeRelative(key, true, index, parentId)
        }
        // 更改all
        var flag = true
        for (let i = 0; i < this.oneArr.length; i++) {
          if (this.oneArr[i].check == false) flag = false
        }
        this.allCheck = flag
      }
      this.$emit('changeBookmark', this.oneArr, this.allCheck, id, itemName)
      // 为了触发view立即更新
      if (!this.oneArr[index]) return
      if (this.oneArr[index].bookmarkName.slice(this.oneArr[index].bookmarkName.length - 1) == ' ') {
        this.oneArr[index].bookmarkName = this.oneArr[index].bookmarkName.slice(0, this.oneArr[index].bookmarkName.length - 1)
      } else {
        this.oneArr[index].bookmarkName += ' '
      }
      this.controller.check[key] = arr
    }
  },
  watch: {
    bookmark (val) {
      if (this.flag) {
        this.initBookmark()
        this.flag = false
      }
    }
  }
}
</script>
<style lang='less' scoped>
.cascader {
  position: relative;
  height: 100%;
}
.z_index {
  z-index: 10000;
}
.block {
  height: 100%;
  text-align: left;
  li {
    padding-left: 30px;
    padding-right: 10px;
    margin-bottom: 10px;
    background-image: url('../assets/img/download.png');
    background-repeat: no-repeat;
    background-position: 6px -46px;
    height: 24px;
    line-height: 24px;
    .bookmark_name {
      width: 88%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  li.active {
    background-color: rgba(57, 170, 80, 0.25);
  }
  li.check {
    background-position: 6px -19px;
  }
}
.second,
.three,
.four {
  padding: 10px 0;
  margin-left: -3px;
  width: 25%;
  border: none;
  background: #333;
  box-shadow: 5px 3px 12px rgba(0, 0, 0, 0.4);
}
</style>
