import { render, staticRenderFns } from "./Cascader.vue?vue&type=template&id=3b1b8a52&scoped=true"
import script from "./Cascader.vue?vue&type=script&lang=js"
export * from "./Cascader.vue?vue&type=script&lang=js"
import style0 from "./Cascader.vue?vue&type=style&index=0&id=3b1b8a52&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b1b8a52",
  null
  
)

export default component.exports